import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import Modal from 'components/Modal';

const Content = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000;
`;

const ModalCouponError = (props) => {
  const { isOpen, onCancel, onOk, text } = props;
  return (
    <Modal
      isOpen={isOpen}
      title=""
      okText="確定"
      onOk={onOk}
      onCancel={onCancel}
      showCancelBtn={false}
    >
      <Content>{parse(text)}</Content>
    </Modal>
  );
};

export default ModalCouponError;
