import React from 'react';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';

const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 10px;

  ${(props) =>
    props.btnCenter &&
    css`
      justify-content: center;
    `}
`;

const Btn = styled.button`
  width: 129px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  padding: 0;
`;

const CancelBtn = styled(Btn)`
  background-color: #c4c4c4;
`;

const OkBtn = styled(Btn)`
  background-color: #5fd2da;
`;

const Modal = (props) => {
  const {
    cancelText = '取消',
    children,
    footer = true,
    isOpen = false,
    okText = '確定',
    onAfterClose = () => {},
    onCancel = () => {},
    onOk = () => {},
    showCancelBtn = true,
    showOkBtn = true,
    title,
    width = '80%',
  } = props;

  return (
    <ReactModal
      {...props}
      style={{ content: { width } }}
      isOpen={isOpen}
      closeTimeoutMS={200}
      htmlOpenClassName="ReactModal__Html--open"
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
      onAfterClose={onAfterClose}
      ariaHideApp={false}
    >
      <Header>{title}</Header>

      {children}

      {footer && (
        <Footer btnCenter={!showCancelBtn || !showOkBtn}>
          {showCancelBtn && (
            <CancelBtn onClick={onCancel}>{cancelText}</CancelBtn>
          )}
          {showOkBtn && <OkBtn onClick={onOk}>{okText}</OkBtn>}
        </Footer>
      )}
    </ReactModal>
  );
};

export default Modal;
